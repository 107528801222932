#Home {
  overflow-y: hidden;
  margin-top: 4rem;
}

.logo {
  width: 35rem;
}

.homeflex {
  display: flex;
  flex-direction: row;
  column-gap: 18%;
  align-items: center;
  justify-content: flex-end;
  align-content: center;
}

.vectorimg img {
  width: 110%;
  position: relative;
  margin: 6rem 0rem 0rem;
  filter: brightness(0.5);
}

.content h1 {
  color: #000;
  font-family: Exo;
  font-size: 340%;
  font-style: normal;
  font-weight: 700;
  padding: 0rem 0rem;
  position: relative;
  line-height: 24px;
  right: 0;
  background-color: #fff;
  margin-top: 5rem;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10rem;
}
.pagedots {
  z-index: 5;
  position: relative;
}

.content h1 span {
  color: #fff;
  background: #000;
  padding: 1% 6rem 1% 2rem;
  border-radius: 4rem 0rem 0rem 4rem;
}

.content p {
  color: #5e3714;
  font-family: "Inria";
  background-color: #fff;
  font-size: 1.875rem;
  font-style: normal;
  width: 51rem;
  height: 6rem;
  flex-shrink: 0;
  font-weight: 700;
  align-self: flex-end;
  line-height: 37px;
  text-transform: capitalize;
}

.bottomvec {
  position: relative;
  left: 32%;
  bottom: 5rem;
}

.bottomvec img {
  width: 75%;
  z-index: -1;
  position: relative;
  right: 0px;
}

.content button {
  width: 13.85rem;
  height: 3.475rem;
  flex-shrink: 0;
  border: none;
  align-self: center;
  border-radius: 11.8125rem;
  background-color: #b24444;
  z-index: 34;
  transition: 0.3s;
}
.content button:hover {
  background: rgb(118 9 9 / 88%);
}

@font-face {
  font-family: Exo;
  src: url(../../assets/fonts/Exo-VariableFont_wght.ttf);
}

.vectorimg {
  position: relative;
  left: 7rem;
}

.content button a {
  color: #fff;
  font-family: Exo;
  font-size: 1.875rem;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  line-height: normal;
}
@media (max-width: 767px) {
  .content h1 {
    padding: 0rem 3rem;
    font-size: 225%;
    display: flex;
    flex-direction: column;
    margin: 2rem 2rem 1rem;
    align-items: center;
    align-content: center;
    justify-content: center;
    background: none;
    align-self: center;
  }

  .homeflex {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: 0 1rem;
  }

  .vectorimg {
    align-self: center;
    position: absolute;
    opacity: 0.3;
    top: 5rem;
    left: -32px;
  }
  .content h1 span {
    border-radius: 5rem;
    margin: 1rem 11rem;
    padding: 1rem 2rem;
  }

  .content p {
    margin: 0rem 3rem 9rem;
    text-align: center;
    padding: 0 29%;
    font-size: 160%;
    line-height: 1.3em;
  }

  .content button {
    width: 12rem;
    height: 2.8rem;
    font-size: 135%;
    margin-bottom: 3rem;
  }

  .content {
    margin: 0;
  }

  .bottomvec {
    display: none;
  }
  .vectorimg img {
    margin: 0;
    filter: brightness(1);
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .content h1 {
    padding: 1rem 1rem;
    font-size: 294%;
    display: flex;
    flex-direction: column;
    margin: 1rem 1rem;
    align-items: center;
    justify-content: center;
    background: none;
  }

  .homeflex {
    display: flex;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    margin: 0 1rem;
  }

  .vectorimg {
    align-self: center;
    position: absolute;
    opacity: 0.19;
    top: 0;
    left: 0;
    margin-left: 16%;
  }
  .vectorimg img {
    margin: 0;
    filter: brightness(0.89);
  }
  .content h1 span {
    border-radius: 5rem;
    margin: 1rem 11rem;
    padding: 1rem 2rem;
  }

  .content p {
    margin: 1rem 0rem 5rem 0;
    text-align: center;
    padding: 0 17%;
    background: none;
    z-index: 4;
  }

  .content button {
    width: 12rem;
    height: 2.8rem;
    filter: drop-shadow(4px 4px 0px #c95959);
    font-size: 0rem;
  }

  .content {
    margin: 0;
  }

  .bottomvec {
    left: -5%;
    bottom: -5rem;
    position: absolute;
    width: 200%;
    z-index: 3;
  }
  .bottomvec img {
    width: 22%;
    z-index: -1;
    filter: brightness(1.4);
    position: relative;
    right: 0px;
  }
}
