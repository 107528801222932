#navbar {
  display: flex;
  align-content: center;
  padding: 0rem 0rem;
  align-items: center;
  column-gap: 17%;
  flex-direction: row;
  background: #000;
  justify-content: flex-start;
  scrollbar-width: none;
  width: 100%;
}

.tabs {
  display: flex;
  flex-direction: row;
  width: inherit;
  position: relative;
  left: 14%;
  align-items: center;
}
.logo {
  width: 25rem;
}

.logo img {
  width: 100%;
  margin: 0rem 0rem 0rem 5rem;
}

.tabs h5 {
  color: #fff;
  transition: transform 0.4s;
  text-decoration: none;
  letter-spacing: 0.008em;
  font-weight: 700;
}
.tabs h5::after {
  width: 0%;
  display: flex;
  content: " ";
  background-color: #ce9045;
  margin: 0rem 0rem;
  position: relative;
  left: 33%;
  height: 4px;
  border-radius: 5px;
  align-self: center;
  align-items: center;
  justify-content: center;
  top: 23%;
  transition: 0.4s;
}
.tabs h5:hover::after {
  width: 25%;
}
.tabs h5 a:hover{
    .caretdown{
        rotate: 90deg;
    }
}
.tabs h5 a:active:focus{
    .caretdown{
        rotate: 90deg;
    }
}
@font-face {
  font-family: Inria;
  src: url(../../assets/fonts/InriaSans-Bold.ttf);
}

.tabs h5 a {
  color: #fff;
  text-decoration: none;
  font-size: 143%;
  margin: 1% 1rem;
  font-family: Inria;
  display: flex;
  flex-direction: row;
  align-items: center;
  transition: 0.4s;
}
.logo {
  width: 30rem;
}
body {
  scrollbar-width: none;
}

@media (max-width: 767px) {
  #navbar {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow-x: hidden;
}

.logo {
    max-width: 15rem;
    align-self: center;
}

.logo img {
    margin: 0;
    width: 90%;
}

.tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: solid #ffffff91 2px;
    align-items: center;
    padding: 2.5% 0rem 2.5%;
    justify-content: center;
    left: 0;
}

.tabs h5 {
    margin: 1px;
    font-size: 65%;
}
.tabs h5::after:active{
  width: 0%;
  display: flex;
  content: " ";
  background-color: #ce9045;
  margin: 0rem 0rem;
  position: relative;
  left: 33%;
  height: 4px;
  border-radius: 5px;
  align-self: center;
  align-items: center;
  justify-content: center;
  top: 23%;
  transition: 0.4s;
}
}
@media (min-width: 767.5px) and (max-width: 991px) {
  #navbar {
    display: flex;
    flex-direction: column;
    z-index: 789;
}

.logo {
    max-width: 15rem;
    align-self: center;
}

.logo img {
    margin: 0;
    width: 90%;
}

.tabs {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border-top: solid #ffffff91 2px;
    align-items: center;
    padding: 2.5% 0rem 2.5%;
    justify-content: center;
    left: 0;
}

.tabs h5 {
    margin: 1px;
    font-size: 65%;
}
.tabs h5::after:active{
  width: 0%;
  display: flex;
  content: " ";
  background-color: #ce9045;
  margin: 0rem 0rem;
  position: relative;
  left: 33%;
  height: 4px;
  border-radius: 5px;
  align-self: center;
  align-items: center;
  justify-content: center;
  top: 23%;
  transition: 0.4s;
}
}