.flexbox,
.flex {
  overflow-y: hidden;
}
.pricebtn,
.pricebtn2 {
  position: relative;
  width: 13.85rem;
  height: 3.475rem;
  flex-shrink: 0;
  border: none;
  align-self: center;
  border-radius: 11.8125rem;
  background-color: #b24444;
  z-index: 34;
  transition: 0.3s;
}
.pricebtn:hover {
  background: rgb(118 9 9 / 88%);
}
.pricebtn a p,
.pricebtn2 a p {
  color: #fff;
  font-family: Exo;
  font-size: 191%;
  font-style: normal;
  font-weight: 600;
  cursor: pointer;
  text-decoration: none;
  line-height: 32%;
}
.pricebtn a p span,
.pricebtn2 a p span {
  text-decoration: line-through;
  color: #000;
}
/* Frame.jsx ____________________________________ */
.flexbox {
  display: flex;
  flex-direction: row;
  background: #f6e5e5;
  min-width: fit-content;
}
.imgdiv {
  width: 25%;
  border: 4px solid rgb(118 9 9 / 60%);
  margin: 0rem 9% 1% 9%;
  border-radius: 19px;
}

.framescreening {
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  scroll-behavior: smooth;
  overflow: hidden;
}

.productscreening {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 2rem 4rem 0 1rem;
  justify-content: center;
  column-gap: 0rem;
}
.infodiv h1 {
  color: #000;
  font-family: IBM;
  font-size: 320%;
  margin-bottom: 0;
  font-style: normal;
  line-height: normal;
  text-transform: capitalize;
}
@font-face {
  font-family: Exo;
  src: url(../../assets/fonts/Exo-VariableFont_wght.ttf);
}
@font-face {
  font-family: biryani;
  src: url(../../assets/fonts/Biryani-Bold.ttf);
}
@font-face {
  font-family: IBM;
  src: url(../../assets/fonts/IBMPlexSans-SemiBold.ttf);
}
@font-face {
  font-family: Inria;
  src: url(../../assets/fonts/InriaSans-Bold.ttf);
}
.infodiv p {
  color: #000;
  font-family: Inria;
  margin: 2% 1% 0%;
  font-size: 235%;
  font-style: normal;
  font-weight: 800;
  text-align: left;
  line-height: normal;
  text-transform: capitalize;
}

.infodiv {
  display: flex;
  margin-bottom: 5rem;
  flex-direction: column;
  align-items: flex-start;
}
.pricebtn {
  margin: 0rem 0rem 5% 49%;
  left: 4rem;
  bottom: 2rem;
}

/* Gift.jsx ____________________________________ */
.pricebtn2 {
  position: relative;
  width: 13.85rem;
  height: 3.475rem;
  flex-shrink: 0;
  border: none;
  align-self: center;
  border-radius: 11.8125rem;
  background-color: #bc3d3de0;
  z-index: 34;
  transition: 0.3s;
}
.pricebtn2:hover {
  background-color: #a35959;
}
.background3 {
  width: 89%;
  position: relative;
  bottom: 9rem;
}

.tscreening {
  margin-top: 0rem;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  column-gap: 0rem;
  justify-content: space-between;
  overflow: hidden;
  scroll-behavior: smooth;
  align-content: center;
  flex-wrap: nowrap;
}

.productimg {
  width: 90%;
  border-radius: 2rem;
}

.giftcontent {
  margin: 2rem 0rem;
  display: flex;
  background: #cfa4a4;
  border-radius: 3rem;
  padding: 1rem 0;
  flex-direction: column;
  align-items: center;
  z-index: 123;
}

.name h4 {
  font-size: 170%;
  max-width: 22rem;
  margin: 1rem 0rem;
  text-align: center;
}

.name {
  align-self: center;
  display: flex;
}
.flex {
  min-width: 25rem;
  overflow-y: hidden;
  margin: 0rem 3rem;
}

@media (max-width: 767.5px) {
  .infodiv h1 {
    font-size: 140%;
    align-self: center;
  }

  .infodiv p {
    text-align: center;
    font-size: 100%;
    margin-bottom: 1rem;
  }

  .infodiv {
    margin: 1rem;
    text-align: center;
  }

  .productscreening {
    display: flex;
    flex-direction: column;
    padding: 2.5% 1rem;
    align-items: center;
  }

  .informationdiv {
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
    flex-direction: column;
  }

  .pricebtn {
    margin: 0;
    left: 0;
    bottom: 0;
    width: 50%;
    font-size: 66%;
    height: 20%;
    margin-bottom: 2rem;
  }

  .pricebtn a p {
    padding: 0;
    margin: 0;
    font-size: 160%;
  }

  .imgdiv {
    margin-top: 1rem;
    width: 70%;
  }

  .productimg {
    width: 85%;
    padding: 0;
    margin: 0;
  }

  .giftcontent {
    margin: 0px 0rem;
    border-radius: 2rem;
    width: 100%;
    padding: 1rem 1rem;
    background-color: transparent;
  }

  .flex {
    min-width: 100%;
    margin: 0;
  }

  .name h4 {
    font-size: 200%;
  }

  .pricebtn2 {
    width: 8rem;
    height: 3rem;
  }

  .pricebtn2 a p {
    font-size: 126%;
    padding: 0;
    margin: 0;
  }

  img.productimg {
    width: 70;
  }

  .screening {
    margin: 0;
    width: auto;
  }

  .productimg {
    width: 85%;
    padding: 0;
    margin: 0;
  }

  .giftcontent {
    margin: 0px 0rem;
    border-radius: 2rem;
    width: 100%;
    padding: 1rem 1rem;
  }

  .flex {
    min-width: 100%;
    margin: 0;
  }

  .pricebtn2 {
    width: 8rem;
    height: 3rem;
  }

  .pricebtn2 a p {
    font-size: 110%;
    padding: 0;
    margin: 0;

  }

  img.productimg {
    width: 70;
  }

  .screening {
    margin: 0;
    width: auto;
  }

  .tscreening {
    background: #ffcaca;
  }

  .sometitle h1 {
    font-size: 196%;
    margin: 3rem 0;
  }
}
@media (min-width: 767.5px) and (max-width:991px) {
  .infodiv {
    margin-bottom: 1rem;
}

.infodiv h1 {
    font-family: 'Exo';
    font-size: 150%;
}

.infodiv p {
    font-size: 110%;
    margin: 1% 2% 0;
}

.pricebtn {
    left: 0;
    bottom: 0;
    margin: 1rem 0rem 3rem 9rem;
    width: 40%;
    height: 2.84rem;
}

.pricebtn a p {
    font-size: 140%;
}

.imgdiv {
    width: 40%;
}

.nextbtn,.prevbtn {
    height: 40%;
    font-size: 85%;
}

.productimg {
    width: 90%;
    border-radius: 1rem;
}

.giftcontent {
    width: 18rem;
    margin: 0;
    border-radius: 2rem;
}

.name h4 {
    font-size: 131%;
}

.pricebtn2 {
    width: 59%;
    height: 3.125rem;
}

.pricebtn2 a p {
    font-size: 100%;
}
.flex {
  margin: 1rem -5rem 1rem 1rem;
}
}