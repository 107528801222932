.accordian {
  display: flex;
  align-content: center;
  align-items: baseline;
  flex-wrap: wrap;
}
.right,
.down {
  max-width: 2rem;
  min-width: 2rem;
}
.answers {
  text-align: left;
  padding: 1rem 2rem;
  color: #fff;
  margin: 0rem 2rem;
  background: rgb(118 9 84 / 88%);
  border-radius: 0 4rem 4rem 4rem;
  filter: drop-shadow(-5px 0px 0px #c38cb2);
  font-size: 117%;
}

.indicators {
  min-height: 2rem;
  background: #fff;
  align-items: center;
  border-radius: 5rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
}

.question {
  margin: 0% 0% 0% 2%;
  font-size: 140%;
  font-family: "Exo";
}

.overlay1 {
  padding-left: 6rem;
}

.questionaire {
  background: #f0c9c9;
  padding-top: 0%;
  min-width: 60%;
  border-radius: 0 10rem 10rem 0rem;
  max-width: 60%;
}
.flexboxfaq {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

img.faqimg {
  margin-left: 15%;
  width: 15%;
}
.sometitle h1 {
  font-size: 275%;
  background: #fff;
}
.moreFAQs {
  color: #fff;
  font-family: Exo;
  font-size: 140%;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  line-height: normal;
  border-radius: 11.8125rem;
  background: rgba(36, 36, 36, 0.88);
  padding: 0.6% 1%;
  transition: transform 0.4s;
  margin-top: 1rem;
  margin-bottom: 5rem;
  cursor: pointer;
}
.moreFAQs:hover {
  transform: scale(0.98);
}
.moreFAQs a {
  text-decoration: none;
  color: #fff;
}
@media (max-width: 767px) {
  .flexboxfaq {
    width: 100%;
    flex-direction: column;
    display: flex;
    align-items: flex-start;
    background: #f0c9c994;
  }

  .accordian {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: nowrap;
    margin: 1rem 0rem;
    margin-left: 5px;
    justify-content: flex-start;
  }

  .question {
    font-size: 90%;
    font-weight: 700;
  }

  .indicators {
    margin: 0;
  }

  .faqimg {
    display: none;
  }

  .moreFAQs {
    margin: 2rem 0 5rem;
    padding: 1.4%;
  }

  .questionaire {
    background: transparent;
    min-width: 100%;
  }

  .moreFAQs a {
    font-size: 100%;
    padding: 0rem 1rem;
  }
  .answers {
    font-size: 75%;
    border-radius: 0rem 2rem 2rem 2rem;
  }
  .overlay1 {
    padding: 0;
    margin: 0;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .overlay1 {
    width: 120%;
  }

  .questionaire {
    min-width: 99%;
  }

  .answers {
    width: 65%;
  }

  .moreFAQs {
    margin-top: 1rem;
  }
}
