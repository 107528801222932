.products,
.overlay {
  z-index: 120;
  margin: 0 0;
  overflow-y: hidden;
}
* {
  box-sizing: border-box;
}
@font-face {
  font-family: Exo;
  src: url(../../assets/fonts/Exo-VariableFont_wght.ttf);
}
@font-face {
  font-family: biryani;
  src: url(../../assets/fonts/Biryani-Bold.ttf);
}
@font-face {
  font-family: IBM;
  src: url(../../assets/fonts/IBMPlexSans-SemiBold.ttf);
}
.shopbtn {
  color: #fff;
  font-family: Exo;
  font-size: 140%;
  font-style: normal;
  font-weight: 600;
  text-decoration: none;
  line-height: normal;
  border-radius: 11.8125rem;
  background: rgba(36, 36, 36, 0.88);
  padding: 0.6% 1%;
  transition: transform 0.4s;
  margin-top: 1rem;
}
.shopbtn:hover {
  transform: scale(0.97);
}
.shopbtn a {
  text-decoration: none;
  color: #fff;
  padding: 0 1rem;
}
.background1 img {
  position: absolute;
  left: 5%;
}

.background2 img {
  position: relative;
  left: 33rem;
}
.background2 {
  height: 12rem;
}
.frametitle h1,
.producttitle h1 {
  color: #fffefe;
  text-align: center;
  font-family: Biryani;
  z-index: 6;
  font-style: normal;
  line-height: normal;
  margin: 0;
  font-size: 160%;
  background: #a61616;
  border-radius: 0 5rem 5rem 0;
  text-transform: capitalize;
  display: flex;
  width: fit-content;
  padding: 1% 3%;
  position: relative;
}
.background3 img {
  position: absolute;
  right: 15rem;
  rotate: 35deg;
}

.background4 img {
  position: relative;
  left: 2rem;
  margin: -8rem 1rem;
  bottom: 5rem;
  rotate: 308deg;
  z-index: -443;
}

.background4 {
  width: fit-content;
}

.prevbtn,
.nextbtn {
  border: none;
  position: absolute;
  width: 24px;
  margin: 0rem 2%;
  align-items: center;
  height: 58%;
  background: none;
  font-size: 133%;
  color: #9a7c7c;
  transition: 0.4s;
  cursor: pointer;
  z-index: 55;
}
.prevbtn:hover,
.nextbtn:hover {
  color: #7d6969;
}
.prevbtn {
  left: 0;
  display: flex;
}
.nextbtn {
  right: 0;
  display: flex;
}
@media (max-width: 767.5px) {
  .background1,
  .background3,
  .background2,
  .background4 {
    display: none;
  }

  .frametitle h1,
  .producttitle h1 {
    font-size: 120%;
    margin: 2rem 0rem;
  }
  .nextbtn,.prevbtn{
    font-size: 80%;
  }
  
}
@media (min-width: 768px) and (max-width: 991px) {
  .background1,
  .background3,
  .background2,
  .background4 {
    display: none;
  }

  .frametitle h1,
  .producttitle h1 {
    font-size: 120%;
    margin: 2rem 0rem;
  }
.nextbtn,.prevbtn {
    height: 40%;
    font-size: 85%;
}
}
