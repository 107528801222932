body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    scroll-behavior: smooth;  
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
  scrollbar-width: none;
  overflow: auto;
 
  body::-webkit-scrollbar {
    width: 10px;
    background-color: transparent;
    border-radius: 5px;
    margin-right: 2px;
  }
  
  body::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
  
  body::-webkit-scrollbar-thumb {
    background: #56e1e6;
    border-radius: 5px;
    margin-right: 2px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
